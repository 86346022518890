<template>
  <el-drawer v-model="drawer" :close-on-click-modal="false" size="1050px">
    <template #header>
      <div class="header_top">
        <div>收款详情</div>
        <div>
          <el-button type="primary" @click="save" size="small">保存</el-button>
        </div>
      </div>
    </template>
    <el-form
      :model="form"
      label-width="100px"
      :rules="rules"
      size="small"
      :inline="true"
      ref="ruleFormRef"
    >
      <el-form-item label="客户名称：" prop="customerId">
        <select-common
          placeholder="请选择客户"
          v-model:modelValue="form.customerId"
          :options="customerOptions"
          class="w390 ml-mr-0"
          labelKey="customerName"
          valueKey="id"
          @selectChange="changeCustomer"
        />
      </el-form-item>
      <el-form-item label="收款日期：" prop="paymentDate">
        <el-date-picker
          style="width: 145px"
          value-format="YYYY-MM-DD"
          type="date"
          placeholder="请选择开始日期"
          v-model="form.paymentDate"
          :clearable="false"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="单据编码：" prop="documentCode">
        <el-input
          v-model="form.documentCode"
          placeholder="请输入单据编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="结算方式：" prop="settlementModeId">
        <settleSelect v-model:methodId="form.settlementModeId" />
      </el-form-item>
      <el-form-item label="结算说明：" prop="settlementExplain">
        <el-input v-model="form.settlementExplain" class="w390"></el-input>
      </el-form-item>
      <el-form-item label="经办人：" prop="accountingId">
        <select-common
          placeholder="请选择经办人"
          v-model:modelValue="form.accountingId"
          :options="props.userOptions"
          valueKey="id"
          labelKey="CnName"
          class="w145 ml-mr-0"
        />
      </el-form-item>
      <el-form-item label="收款说明：" prop="paymentExplain">
        <el-input v-model="form.paymentExplain" class="w880"></el-input>
      </el-form-item>
      <el-form-item label="到账金额：">
        <el-input
          v-model="form.receivedAmount"
          class="w145"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="实际收款：">
        <el-input v-model="form.actualPayment" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="最终修改人：">
        <el-input class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="审核人：" prop="auditUser">
        <el-input v-model="form.auditUser" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="单据状态：">
        <div class="w145">待提交</div>
      </el-form-item>
      <el-form-item label="录入人：">
        <el-input v-model="form.cnName" class="w145" disabled></el-input>
      </el-form-item>
    </el-form>
    <el-card style="width: 100%">
      <template #header>
        <div class="header_top">
          收款明细
          <div>
            <el-button size="small" @click="addServer" type="primary"
              >新增</el-button
            >
            <el-button size="small" @click="delServer" type="danger" plain
              >删除</el-button
            >
          </div>
        </div>
      </template>
      <el-table
        :data="form.newCrmPayment"
        style="width: 100%"
        show-summary
        border
        @select-change="handleSelectChange"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="收款说明" width="130">
          <template #default="{ row }">
            <el-input
              v-model="row.paymentExplain"
              class="w130"
              size="small"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="合同(单据)号" width="130">
          <template #default="{ row }">
            <el-input
              v-model="row.contractNum"
              class="w130"
              size="small"
              disabled
            >
              <template #append
                ><el-button icon="Search" @click="getContractInfo" size="small"
              /></template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="应收日期" width="130">
          <template #default="{ row }">
            {{ row.receivableTime }}
          </template>
        </el-table-column>
        <el-table-column label="应收月份" width="250">
          <template #default="{ row }">
            <div v-if="row.paymentStartMonth">
              {{ row.paymentStartMonth }} - {{ row.paymentEndMonth }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="费用类型" width="150">
          <template #default="{ row }">
            {{ row.costType }}
          </template>
        </el-table-column>
        <el-table-column
          prop="receivableAmount"
          label="应收金额"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="paymentDiscount"
          label="本次优惠"
          min-width="100"
        >
          <template #default="{ row }">
            <qzf-input
              v-model:num="row.paymentDiscount"
              @changeLx="changeAmount"
            /> </template
        ></el-table-column>
        <el-table-column prop="receivedAmount" label="本次收款" min-width="100">
          <template #default="{ row }">
            <qzf-input
              v-model:num="row.receivedAmount"
              @changeLx="changeAmount"
            /> </template
        ></el-table-column>
        <el-table-column label="手续费" min-width="100" prop="serviceCharge">
          <template #default="{ row }">
            <qzf-input
              v-model:num="row.serviceCharge"
              @changeLx="changeAmount"
            /> </template
        ></el-table-column>
        <el-table-column label="到账金额" min-width="100" prop="daozhangAmount">
          <template #default="{ row }">
            <qzf-input
              v-model:num="row.daozhangAmount"
              :disabled="true"
            /> </template
        ></el-table-column>
      </el-table>
    </el-card>
  </el-drawer>
</template>

<script setup>
import { ref, defineExpose, onMounted, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import {
  customerList,
  contractPaymentTypeList,
  saveContractPaymentRecord,
  contractPaymentList,
} from "@/api/newCrm.js";
import { getCurrentDate } from "@/utils/fun";
import settleSelect from "./settleSelect.vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  userOptions: {
    type: Array,
  },
});
const emit = defineEmits();
const drawer = ref(false);
const form = ref({
  newCrmPayment: [],
  auditStatus: 1,
  receivedAmount: 0,
  actualPayment: 0,
});
const paymentTypeOptions = ref([]);
const customerOptions = ref([]);
const ruleFormRef = ref();
const rules = ref({
  customerId: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
  businessTypeId: [
    { required: true, message: "请选择业务类型", trigger: "change" },
  ],
});
const sels = ref([]);
onMounted(() => {
  contractPaymentTypeList({}).then((res) => {
    paymentTypeOptions.value = res.data.data.list;
  });
});
const init = (item) => {
  getCustomerList();
  reset();
  if (item) {
    form.value = Object.assign({}, item);
    form.value.newCrmPayment = form.value.newCrmPayment
      ? form.value.newCrmPayment
      : [];
    form.value.renewalType = 1;
  } else {
    form.value.paymentDate = getCurrentDate();
    form.value.cnName = proxy.$store.getters["user/user"].cnName;
    form.value.accountingId = proxy.$store.getters["user/user"].userId;
  }
  drawer.value = true;
};
const getCustomerList = () => {
  customerList({ customerType: "2" }).then((res) => {
    if (res.data.msg == "success") {
      customerOptions.value = res.data.data.list;
    }
  });
};
const changeCustomer = () => {
  contractPaymentList({
    customerId: form.value.customerId,
    paymentDate: form.value.paymentDate,
  }).then((res) => {
    if (res.data.msg == "success") {
      form.value.newCrmPayment = res.data.data.list;
      changeAmount();
    }
  });
};
const changeAmount = () => {
  form.value.receivedAmount = 0;
  form.value.actualPayment = 0;
  form.value.newCrmPayment?.map((v) => {
    v.receivedAmount = v.receivableAmount - v.paymentDiscount;
    v.daozhangAmount = v.receivedAmount - v.serviceCharge;
    form.value.actualPayment += v.daozhangAmount;
    form.value.receivedAmount = form.value.actualPayment;
  });
};
const getContractInfo = () => {
  contractPaymentList({
    customerId: form.value.customerId,
  }).then((res) => {});
};
const addServer = () => {
  form.value.newCrmPayment.push({
    receivableAmount: 0,
    paymentDiscount: 0,
    receivedAmount: 0,
    serviceCharge: 0,
    daozhangAmount: 0,
  });
};
//多选
const handleSelectChange = (e) => {
  sels.value = e;
};
//删除
const delServer = () => {
  form.value.newCrmPayment = form.value.newCrmPayment.filter((v) => {
    sels.value.forEach((e) => {
      if (v.id == e.id) {
        return true;
      }
    });
  });
};
//保存
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      saveContractPaymentRecord(form.value).then((res) => {
        if (res.data.msg == "success") {
          ElMessage.success("保存成功");
          drawer.value = false;
          emit("success");
        }
      });
    }
  });
};
const reset = () => {
  form.value = {
    newCrmPayment: [],
    auditStatus: 1,
    receivedAmount: 0,
    actualPayment: 0,
  };
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w145 {
  width: 145px !important;
}
.w173 {
  width: 173px !important;
}
.w390 {
  width: 390px !important;
}
.w880 {
  width: 880px;
}
.el-form--inline .el-form-item {
  margin-right: 0px;
}
.ml0 {
  margin-left: 0px;
}
.ml-mr-0 {
  margin: 0;
}
:deep(.el-card__header) {
  padding: 10px 20px;
}
</style>

<template>
  <el-select
    v-model="id"
    placeholder="选择结算方式"
    @change="changeValue"
    filterable
    size="small"
    class="w145 ml-mr-0"
  >
    <el-option
      style="color: #17a2b8"
      label="+新增"
      value="add"
      @click="add"
    ></el-option>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.methodName"
      :value="item.id"
    >
      <div class="text_style">
        <span>{{ item.methodName }}</span>
        <el-button
          link
          size="small"
          @click.prevent.stop="del(item.id)"
          v-if="item.orgId"
          type="danger"
          >删除</el-button
        >
      </div>
    </el-option>
  </el-select>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="新增结算方式"
    width="450px"
  >
    <el-form size="small">
      <el-form-item label="结算方式：" prop="methodName">
        <el-input v-model="methodName"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="createSure"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { paymentMethodList, saveMethod, deleteMethod } from "@/api/newCrm";
export default {
  name: "",
  components: {},
  props: {
    methodId: Number,
  },

  data() {
    return {
      options: [],
      dialogFormVisible: false,
      methodName: "",
    };
  },
  computed: {
    id: {
      get() {
        if (!this.methodId) {
          return "";
        }
        return this.methodId;
      },
      set(val) {
        this.$emit("update:methodId", val * 1);
        this.$emit("success", val * 1);
      },
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      paymentMethodList({}).then((res) => {
        this.options = res.data.data.list ? res.data.data.list : [];
      });
    },
    add() {
      this.id = null;
      this.dialogFormVisible = true;
    },
    createSure() {
      if (!this.methodName) {
        this.$qzfMessage("请输入结算方式");
        return;
      }
      saveMethod({ methodName: this.methodName }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          this.dialogFormVisible = false;
          this.id = res.data.data.info.id;
          this.init();
        }
      });
    },
    del(id) {
      this.id = null;
      deleteMethod({ id }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("删除成功");
          this.init();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.text_style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ml-mr-0 {
  margin: 0;
}
.w145 {
  width: 145px !important;
}
</style>

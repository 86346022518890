<template>
  <el-drawer v-model="drawer" title="Ai操作日志" destroy-on-close size="1200px">
    <div class="top_select">
      <el-scrollbar :maxHeight="130">
        <div class="search_btn">
          <el-button size="small" icon="Search" type="primary" @click="getList">查询</el-button>
        </div>
        <el-form label-position="right" label-width="80px" ref="dataForm" size="small" :inline="true">
          <div>
            <el-form-item label="操作时间：" prop="time">
              <el-date-picker style="width: 160px" size="small" v-model="listQuery.begin" type="datetime"
                placeholder="开始时间" :clearable="false" :disabled-date="disabledDate" />
              -
              <el-date-picker style="width: 160px" size="small" v-model="listQuery.end" type="datetime"
                placeholder="结束时间" :clearable="false" :disabled-date="disabledDate" />
            </el-form-item>
            <el-form-item label="操作人员：" prop="userId" label-width="128px">
              <el-input v-model="listQuery.userId" style="width: 160px" clearable></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="动作：" prop="time">
              <el-select v-model="listQuery.entityFilter[0].op" placeholder="请选择" style="width: 160px; margin: 0"
                clearable>
                <el-option v-for="item in opOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="listQuery.entityFilter[0].value" placeholder="请选择"
                style="width: 160px; margin-right: 0" clearable filterable>
                <el-option v-for="item in entityOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司：" prop="time" label-width="128px">
              <el-select v-model="listQuery.actionFilter[0].op" placeholder="请选择" clearable
                style="width: 160px; margin-left: 0">
                <el-option v-for="item in opOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input v-model="listQuery.actionFilter[0].value" style="width: 160px" clearable></el-input>
            </el-form-item>
          </div>
          <el-form-item v-for="(item, index) in listQuery.targetFilter" label="参数：" :key="item.key">
            <el-select v-model="item.op" placeholder="请选择" clearable style="width: 160px; margin-left: 0">
              <el-option v-for="item in opOption" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="item.value" style="width: 160px; margin-right: 10px" clearable></el-input>
            <el-button @click.prevent="add(index)" size="small" type="primary" icon="Plus" v-if="index == 0">
            </el-button>
            <el-button @click.prevent="remove(index)" size="small" type="danger" plain v-if="index != 0"
              icon="Delete"></el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>
    <el-table :data="list" style="width: 100%" border :height="contentStyleObj" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
      <el-table-column prop="action" label="操作公司" min-width="240">
        <template #default="scope">
          <i class="iconfont icon-fuzhi1" @click="$copyComName(scope.row.action)"></i>
          {{ scope.row.action }}
        </template>
      </el-table-column>
      <el-table-column label="操作人" width="120" prop="userId">
      </el-table-column>
      <el-table-column label="参数" min-width="120" prop="target">
        <template #default="scope">
          <i class="iconfont icon-fuzhi1" @click="$copyComName(scope.row.target)"></i>
          <el-tooltip effect="dark" placement="top-start">
            <template #content>
              <el-scrollbar :maxHeight="350">
                <div style="max-width: 500px">{{ scope.row.target }}</div>
              </el-scrollbar>
            </template>
            {{ scope.row.target }}
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="行为" min-width="100" prop="entity">
      </el-table-column>
      <el-table-column label="操作时间" width="160">
        <template #default="scope">
          <span>{{
            $parseTime(scope.row.eventTime, "{y}-{m}-{d} {h}:{i}:{s}")
            }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.pageNumber"
        v-model:limit="listQuery.pageSize" @pagination="getList" layout="sizes, prev, pager, next" />
    </div>
  </el-drawer>
</template>

<script>
import { getLogNew } from "@/api/company";
import { OPERATION_LOG_OPTIONS } from '@/utils/commonData.js'
export default {
  name: "aiOperationLog",
  data() {
    return {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      drawer: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 20,
        entityFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        actionFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        targetFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        category: "v3",
        begin: new Date(),
        end: new Date(),
      },
      list: [],
      contentStyleObj: {},
      loading: false,
      total: 0,
      opOption: [
        {
          label: "模糊查询",
          value: "like",
        },
        {
          label: "全等查询",
          value: "eq",
        },
        {
          label: "不等查询",
          value: "neq",
        },
      ],
      entityOption: OPERATION_LOG_OPTIONS
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(320);
  },
  methods: {
    init() {
      this.drawer = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getLogNew(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data;
          this.total = res.data.data && res.data.data.length ? 9999 : 0;
        }
      });
    },
    add() {
      this.listQuery.targetFilter.push({
        op: "",
        key: Date.now(),
        value: "",
      });
    },
    remove(index) {
      this.listQuery.targetFilter.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  margin-bottom: 10px;

  .search_btn {
    position: absolute;
    right: 20px;
  }
}

.icon-fuzhi1 {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
</style>
